<template>
  <div id="detail">
    <invoice-header text="电子发票详情"></invoice-header>
    <div class="rows title1">
      <span>电子发票</span>
      <span>{{invoiceDetail.state | parseInvoiceState}}</span>
    </div>
    <div class="rows">
      <span>抬头类型</span>
      <span>个人/非企业单位</span>
    </div>
    <div class="rows">
      <span>发票抬头</span>
      <span>{{invoiceDetail.head}}</span>
    </div>
    <div class="rows">
      <span>发票内容</span>
      <span>*信息技术服务*信息服务费</span>
    </div>
    <div class="rows">
      <span>发票金额（元）</span>
      <span class="money">{{invoiceDetail.amount}}</span>
    </div>
    <div class="rows">
      <span>申请时间</span>
      <span>{{invoiceDetail.time}}</span>
    </div>
    <div class="rows title2">
      <span>接收信息</span>
    </div>
    <div class="rows">
      <span>电子邮件</span>
      <span>{{invoiceDetail.email}}</span>
    </div>
    <div class="rows">
      <span>客服QQ ： {{qq}}</span>
    </div>

    <div class="btns">
      <button @click="onResend" :disabled="btnDisabled">重发发票</button>
      <button @click="onReApply" :disabled="btnDisabled">申请重开发票</button>
    </div>
  </div>
</template>

<script>
import { getKfqq } from "./invoice";

export default {
  created() {
    getKfqq().then(res => (this.qq = res));
    let InvoiceDetail = sessionStorage.getItem("InvoiceDetail");
    if (InvoiceDetail) {
      this.invoiceDetail = JSON.parse(InvoiceDetail);
    }
  },
  data() {
    return {
      invoiceDetail: null,
      qq: ""
    };
  },
  components: {
    "invoice-header": require("./header").default
  },
  computed: {
    btnDisabled() {
      const { state } = this.invoiceDetail;
      return state === "20" || state === "21";
    }
  },
  filters: {
    parseInvoiceState(val) {
      let res = "";
      switch (val) {
        case "2":
          res = "开票成功";
          break;
        case "3":
          res = "发票已作废";
          break;
        case "20":
        case "21":
          res = "开票中";
          break;
        case "22":
        case "24":
          res = "开票失败";
          break;
        case "31":
          res = "发票作废中";
          break;
      }
      return res;
    }
  },
  methods: {
    onResend() {
      this.$router.push({ name: "invoice_resend" });
    },
    onReApply() {
      const { state } = this.invoiceDetail;
      if (state === "22" || state === "24") {
        this.$router.push({ name: "invoice_order" });
        return;
      }
      this.$router.push({ name: "invoice_re_apply" });
    }
  }
};
</script>

<style lang="scss">
#detail {
  .rows {
    border-bottom: 0.01rem solid #e6e6e6;
    height: 0.8rem;
    margin: 0 auto;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    color: #666666;

    span:first-child {
      margin-right: 0.3rem;
    }

    span.money {
      color: #e10000;
    }

    &.title1 {
      justify-content: space-between;
      span:first-child {
        color: #333333;
      }
    }

    &.title2 {
      justify-content: center;
      span {
        font-size: 0.24rem;
        color: #333333;
      }
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
    margin-top: 0.43rem;

    button {
      width: 44.583%;
      height: 0.73rem;
      background-color: #169bd5;
      border-radius: 0.08rem;
      border: none;
      outline: none;
      font-size: 0.3rem;
      color: #ffffff;

      &:disabled {
        background-color: #999999;
      }
    }
  }
}
</style>
